@import 'colors';
@import 'mixin';

@font-face {
  font-family: 'Gfont';
  src: local('MyFont'),
    url(../assets/fonts/Roboto/Roboto-Black.ttf) format('ttf');
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: $white;
  color: $color-text-base;
}

body,
div,
input,
select button,
textarea,
a,
pre {
  font-size: 15px;
  font-family: 'Varela Round', sans-serif;
  vertical-align: top;
  font-weight: 400;
}

::selection {
  background-color: $blue;
  color: $white;
}

::-moz-selection {
  background-color: $blue;
  color: $white;
}

input,
textarea,
select {
  border: 0;
  padding: 10px;
  background: transparent;
  border-bottom: 1px solid $blue;
  margin: 10px 0;
  resize: none;
  outline: none;
  border-radius: 0;
  -webkit-appearance: none;
  min-height: 30px;
  &:focus {
    outline: none;
  }
  &:disabled {
    border: 0;
    // font-style: italic;
  }
}
select {
  padding: 10px;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $grey;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $grey;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: $grey;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $grey;
}

/* page browsing */

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in;
}

.w100 {
  width: 100%;
  @extend .display-flex;
  @extend .flex-center;
  flex-direction: column;
}

h1 {
  font-size: 30px;
  text-transform: uppercase;
  height: 40px;
  @extend .display-flex;
  @extend .flex-center-start;
  .fa-info-circle {
    font-size: 12px;
    margin: 0 0 0 10px;
    &:hover {
      color: $blue;
      cursor: help;
    }
  }
}

h2 {
  font-size: 25px;
  text-align: right;
  border-bottom: 1px solid $grey-300;
  padding: 10px 0;
  margin: 10px 0;
  text-transform: uppercase;
  font-weight: bold;
  &:not(:first-of-type) {
    margin: 20px 0 10px 0;
  }
}

h3 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  &.mt {
    margin-top: 20px;
  }
}

input,
textarea,
select {
  border: 0;
  background: $grey-200;
  resize: none;
  outline: none;
  padding: 5px;
  font-size: 15px;
  font-family: 'Varela Round', sans-serif;
  vertical-align: top;
  font-weight: 400;
  @include border-radius(5px);
  width: 100%;
  @extend .bsbb;
  &:focus {
    outline: none;
  }
  &[disabled] {
    color: $grey;
    background: $grey-300;
  }
}
textarea {
  height: 70px;
}
select,
input {
  height: 28px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
  padding: 0;
  margin: 0 10px 0 0;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    &:checked + .slider {
      background-color: $blue;
    }
    &:checked + .slider:before {
      -webkit-transform: translateX(22px);
      -ms-transform: translateX(22px);
      transform: translateX(22px);
    }
  }
  .slider {
    position: absolute;
    @include border-radius(14px);
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    background-color: $grey-300;
    @include transition(0.4s);
    &:before {
      @include border-radius(50%);
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 4px;
      bottom: 4px;
      background-color: $white;
      @include transition(0.4s);
    }
  }
}

/* Scrollbar customization > works only on: Chrome, Safari and Opera */

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: transparent;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.05);
  background-color: #3f51b5;
}

[disabled] {
  cursor: not-allowed;
}
