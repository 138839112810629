.calendar {
    height: 100%;

    .rbc-date-cell {
        margin-top: 7px;
        margin-bottom: 2px;
    }

    .rbc-row-content {
        pointer-events: none;
    }

    .rbc-event {
        pointer-events: all;
        padding: 5px;
        margin: 2px;
        background-color: #3f51b5;
        &:hover {
            box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.375);
            filter: brightness(120%);
        }
        &.rbc-selected {
            background-color: #3f51b5;
        }
    }

    .rbc-day-bg {
        cursor: pointer;
        padding: 5px;
        &:hover {
            background: #eaf6ff;
        }
        &.rbc-today {
            background: #3174ad;
            opacity: 0.4;
            &:hover {
                background: #3174ad;
            }
        }
    }

    .rbc-row-segment {
        padding: 0px 6px;
        padding-bottom: 1px;
    }

    .rbc-event-content {
        padding: 3px;
    }
}

.dialog .delete {
    color: red;
    position: absolute;
    top: 28px;
    right: 20px;
    width: 35px;
    height: 35px;
    background-size: cover;
    background-image: url("../assets/delete_red.svg");
    cursor: pointer;
}
