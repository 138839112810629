.dnddialog {
    .dialog {
        .modal-container {
            .modal {
                width: 800px;

                form {
                    width: 50%;
                    margin-left: auto;
                    z-index: 5;

                    .input-name {
                        margin-right: 50%;
                    }
                }

                .color-picker {
                    width: 28px;
                    height: 28px;
                    border-radius: 100%;
                    right: 0;
                    margin-left: auto;
                    margin-right: 10px;
                    margin-top: -178px;
                    cursor: pointer;
                    z-index: 6;
                }

                .color-picker::before {
                    content: "Colore Sprint";
                    left: -60px;
                    width: auto;
                    position: relative;
                    font-size: 10px;
                    color: #0a0a0a;
                }

                .board {
                    display: flex;
                    flex-flow: row;

                    // .column:first-child {
                    //     margin-top: -57px;
                    //     padding-right: 30px;
                    //     border-right: 1px solid #E0E0E0;
                    // }

                    // .column:first-of-type::after {
                    //     content: "";
                    //     right: 0;
                    //     top: -39px;
                    //     position: absolute;
                    //     height: calc(100% + 25px);
                    //     width: 1px;
                    //     background: #dfdfdf;
                    // }

                    .column:first-of-type {
                        margin-top: -55px;
                        padding-right: 20px;
                    }
                    .column:last-of-type {
                        margin-top: 130px;
                        margin-bottom: 40px;

                        .cards-container {
                            margin-right: -10px;
                            margin-left: 15px;
                        }
                    }

                    .column {
                        width: 50%;
                        margin-right: 10px;
                        position: relative;

                        label {
                            margin-left: 15px;
                        }

                        .search {
                            display: flex;
                            flex-flow: row;

                            img {
                                margin-left: -30px;
                                opacity: 0.3;
                            }
                        }

                        &:last-of-type .cards-container .card__title {
                            color: white;
                        }

                        .cards-container {
                            padding: 15px;
                            margin-top: 10px;
                            border-radius: 5px;
                            max-height: 410px;
                            height: 410px;
                            overflow-y: scroll;
                            transition: box-shadow 200ms ease-in-out;

                            .card__title {
                                min-height: 30px;
                                padding: 15px 10px;
                                margin-top: 10px;
                                border-radius: 3px;
                                box-shadow: 0px 1px 5px 1px #0000001f;
                                transition: box-shadow 200ms ease-in-out,
                                background-color 100ms ease-in-out;
                                white-space: normal;
                            }
                            .card__title:hover {
                                box-shadow: 0px 4px 5px 0px #0000003f;
                            }
                            .card--dragging .card__title {
                                background-color: rgba(128, 128, 128, 0.473);
                                box-shadow: 0px 4px 5px 0px #0000005f;
                            }

                            img {
                                opacity: 0.55;
                                margin-right: 5px;
                            }

                            span {
                                vertical-align: super;
                            }

                            .card--spacer {
                                height: 200px;
                                .card__title {
                                    box-shadow: none;

                                    img {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .cards-container::-webkit-scrollbar {
                            display: none;
                        }
                        .cards-container {
                            -ms-overflow-style: none;
                            .card {
                                cursor: move;
                            }
                        }

                        &:last-of-type .cards-container {
                            border: 1px solid #efefef;
                            max-height: 250px;
                        }
                    }
                }
            }
        }
    }
}
