* {
  box-sizing: border-box;
}

.page-title {
  margin-bottom: 30px;
}

.dashboard-filter {
  margin-bottom: 40px;
}

.board-container {
  height: calc(100% - 172px);
}

.Board {
  font-family: sans-serif;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  position: relative;
  max-width: 2000px;
  height: 100%;
  // &.sprint::after {
  //     content: "";
  //     height: 2px;
  //     width: 100%;
  //     background: #bdbdbd;
  //     position: absolute;
  //     bottom: -50px;
  // }
}

.sprints {
  height: 100%;

  .sprint {
    height: 100%;
  }

  .board-container {
    height: calc(100% - 210px);
  }
}

.Column {
  display: inline-block;
  vertical-align: top;
  width: calc(20% - 20px);
  height: fit-content;
  border-radius: 5px;
  color: #8d8d8d;
  height: 100%;
  .column-container {
    max-height: calc(100% - 45px);
    overflow-y: auto;
    background: #efefef;
    padding: 15px 15px 0px 15px;
    border-radius: 5px;
    min-height: 200px;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #bdbdbd;
    }
  }
}

.Column__title {
  font-size: 25px;
  margin-bottom: 20px;
}

.Card {
  background: #ffffff;
  color: #373737;
  border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 15px;
  cursor: move;
  .Card__top-info {
    background: #bdbdbd;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-right: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dropdown-wrapper {
      display: flex;
      align-items: center;
      .button-options {
        margin-left: -5px;
        background-image: url('../assets/options_white.svg');
        height: 30px;
        width: 30px;
        background-position: center;
        fill: #fff;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
      }
    }

    .Card__title {
      padding: 15px;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      white-space: normal;
    }

    .icons-container {
      display: flex;
      .Card__icon {
        height: 35px;
        width: 35px;
        background-position: center;
        fill: #fff;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 5px;
        background-image: url('../assets/icons/play.svg');
        cursor: pointer;
        &.recording {
          background-image: url('../assets/stop.svg');
        }
        &.time {
          background-image: url('../assets/clock.svg');
          background-size: 28px 28px;
        }
      }
    }
  }

  .Card__body {
    padding: 18px;
    position: relative;

    .resources-container {
      margin-top: 20px;
      .align-info {
        justify-content: space-between;
      }
    }

    .space-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      &.top {
        margin-top: 10px;
      }
    }

    .align-info {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      min-width: 45%;
      min-height: 26px;

      .evidence {
        font-weight: bold;
        font-size: 17px;
      }

      &.priority {
        div {
          margin-left: 5px;
        }
      }

      .priority {
        width: 20px;
        height: 20px;
        border: solid 1px #adadad;

        &.s-1 {
          background-color: #fafafa;
        }
        &.s-2 {
          background-color: #86d51a;
        }
        &.s-3 {
          background-color: yellow;
        }
        &.s-4 {
          background-color: orange;
        }
        &.s-5 {
          background-color: red;
        }
      }

      label {
        color: #373737;
        font-size: 15px;
        margin: 5px;
        text-transform: none;
      }

      .delete-resource {
        border-radius: 50%;
        height: 20px;
        width: 20px;
        background-image: url('../assets/remove_circle.svg');
        background-position: center;
        cursor: pointer;
        margin: 5px;
      }

      a {
        margin: 5px;
      }

      :first-child {
        margin-right: 5px;
      }
    }
  }
}

.Card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.Card:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.Card--spacer {
  cursor: default;
  //   pointer-events: none;
  .Card__body {
    height: 80px;
  }
}

.Card--spacer,
.Card--dragging {
  opacity: 0;
}

.Card--thin {
  height: 0px;
  margin: 0px;
  display: none;
}

.TextForm__input {
  display: block;
  background: #ffffff;
  width: 100%;
  border-radius: 3px;
  border: none;
  font-size: 16px;
  padding: 5px;
  margin-bottom: 5px;
  opacity: 0.7;
}

.TextForm__input:focus {
  opacity: 1;
}
