@import 'colors';
@import 'mixin';
@import 'social';
a,
button {
  font-family: 'Varela Round', sans-serif;
  @include transition(0.5s);
  outline: none;
  border: 0;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    @include transition(0.5s);
    outline: none;
  }
}

.btn-login {
  background: $microsoftoffice;
  color: $white;
  padding: 10px;
  text-transform: uppercase;
  @include border-radius(2px);
  i {
    margin-right: 10px;
  }
  &:hover {
    background: $red-600;
  }
}

.lnk-nav {
  position: relative;
  @extend .display-flex;
  @extend .flex-center;
  padding: 0;
  @extend .flex-center-start;
  text-decoration: none;
  color: rgba($white, 0.5);
  background: transparent;
  @include border-radius(5px);

  margin: 2px 0;
  &.nm {
    margin: 0;
  }
  &:hover {
    color: $white;
    background: rgba($white, 0.2);
  }

  .icon {
    min-width: 50px;
    height: 50px;
    font-size: 20px;
    @extend .display-flex;
    @extend .flex-center;
    @include border-radius(5px);
    margin-right: 10px;
  }

  .txt {
    text-transform: capitalize;
    // font-weight: bold;
    font-size: 16px;
  }

  &.active {
    color: $white;
  }
}

.lnk-in-submenu {
  // color: $color-text-base;
  background: transparent;
  text-decoration: none;
  width: 100%;
  @extend .bsbb;
  padding: 5px 10px;
  text-align: left;
  font-weight: 400;
  font-size: 15px;
  color: $color-text-base;
  @extend .display-flex;
  @extend .flex-center-start;
  width: 100%;
  height: 30px;
  &:hover {
    background: $grey-100;
  }
}

.lnk-submenu {
  background: transparent;
  color: $white;
  padding: 0;
  margin: 0;
  // font-size: 15px;
  i {
    margin-left: 10px;
  }
}

.lnk-utils {
  background: $blue;
  color: $white;
  font-weight: bold;
  text-transform: uppercase;
  @include border-radius(2px);
  padding: 10px;
  @extend .bsbb;
  height: 40px;
  @extend .display-flex;
  @extend .flex-center;
  text-decoration: none;
  @extend .bkg-colors-status;
  i {
    margin-right: 10px;
  }
  &.nm {
    i {
      margin: 0;
    }
  }
  &[disabled] {
    @include opacity(0.5);
    cursor: not-allowed;
  }
  &.mr {
    margin-right: 20px;
  }
}

.lnk-new {
  @extend .lnk-utils;
  position: absolute;
  top: 20px;
  right: 20px;
}

.btn-flt {
  @include border-radius(5px);
  color: $white;
  padding: 5px;
  text-transform: uppercase;
  color: $color-text-base;
  background: transparent;
  &.active,
  &:hover {
    color: $white;
    &.draft {
      background: $color-draft;
    }
    &.waiting {
      background: $color-waiting;
    }
    &.confirmed {
      background: $color-confirmed;
    }
    &.refused {
      background: $color-refused;
    }
    &.invoiced {
      background: $color-invoiced;
    }
    &.canone,
    &.progetto,
    &.consumo {
      background: $blue;
    }
  }
}

// .lnk-rms {
//   position: absolute;
//   top:20px;
//   right: 20px;
//   width:30px;
//   height: 30px;
//   @extend .display-flex;
//   @extend .flex-center;
//   text-decoration: none;
//   @include border-radius(50%);
//   // padding: 10px;
//   background: $grey-300;
//   color:$grey;
// }

.lnk-nav-ver {
  @extend .display-flex;
  @extend .flex-center;
  background: $blue;
  @include border-radius(2px);
  // padding:10px;
  color: $white;
  text-decoration: none;
  // min-width:40px;
  // min-height: 40px;
  padding: 5px;
  font-size: 12px;
  width: 25px;
  height: 25px;
  @extend .bsbb;
  &[disabled] {
    @include opacity(0.5);
    cursor: not-allowed;
  }
}

.lnk-only-icon {
  color: $white;
  background: $blue;
  @include border-radius(2px);
  @extend .display-flex;
  @extend .flex-center;
  width: 30px;
  height: 26px;
  &.red {
    background: $red;
  }
  &.green {
    background: $green;
  }
  &.trasp {
    background: transparent;
    color: $color-text-base;
  }
  &[disabled] {
    @include opacity(0.5);
  }
}

.lnk-del-eh {
  background: transparent;
  color: $red;
  @include border-radius(50%);
  width: 20px;
  height: 20px;
  @extend .display-flex;
  @extend .flex-center;
  @include opacity(0);
  &:hover {
    background: $red;
    color: $white;
    font-size: 12px;
  }
}

.lnk-close-modal {
  text-decoration: none;
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: transparent;
  border: 0;
  font-size: 20px;
  color: $grey-700;
}

.lnk-submit {
  text-transform: uppercase;
  font-weight: 700;
  border: 0;
  padding: 10px;
  @include border-radius(5px);
  justify-content: center;
  @extend .display-flex;
  // font-size: 12px;
  background: transparent;

  &:disabled {
    @include opacity(0.5);
    cursor: not-allowed;
  }
  &.confirm {
    background: $blue;
    color: $white;
  }
  &.cancel {
    color: $blue;
  }
  &.delete {
    background: $red;
    color: $white;
  }
}

.lnk-add-story {
  @extend .display-flex;
  @extend .flex-center;
  height: 28px;
  background: $grey-100;
  @extend .bsbb;
  margin: 10px 0 0 30px;
  @include calc('width', '100% - 30px');
  @include border-radius(5px);
  text-transform: uppercase;
  font-size: 12px;
  color: $color-text-base;
}

.lnk-confirm-epic {
  color: $color-text-base;
  background: transparent;
  @include border-radius(50%);
  min-width: 25px;
  min-height: 25px;
  max-width: 25px;
  max-height: 25px;
  @extend .display-flex;
  @extend .flex-center;
  &:hover {
    background: $blue;
    color: $white;
  }
  &.yes {
    color: $white;
    background: $blue;
  }
}

.lnk-send-to-invoiced {
  color: $color-text-base;
  background: transparent;
  @include border-radius(5px);
  background: $blue;
  color: $white;
  height: 30px;
  width: 100%;
  position: relative;
  @extend .display-flex;
  @extend .flex-center;
  overflow: hidden;
  &.red {
    background: $red;
  }
  ul {
    @extend .display-flex;
    @extend .flex-center;
    @extend .flex-direction-column;
    position: absolute;
    width: 100%;
    top: 0;
    @include transition(0.5s);
    li {
      width: 100%;
      margin: 0 !important;
      @extend .display-flex;
      @extend .flex-center;
      height: 30px;
    }
  }
  &:not([disabled]) {
    &:hover {
      ul {
        @include transition(0.5s);
        top: -30px;
      }
    }
  }
  &[disabled] {
    @include opacity(0.5);
    cursor: default;
  }
}

.lnk-project-detail {
  color: $blue;
  // color:$color-text-base;
  font-weight: 700;
  text-decoration: none;
}
