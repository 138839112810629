@import "colors";
@import "mixin";
.spinner {
  .circle {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -20px;
    border: 2px solid transparent;
    width: 40px;
    height: 40px;
    z-index: $zi-loader-bullet;
    @extend .display-flex;
    @extend .flex-center;
    img {
      height: 50px;
    }
    &:after {
      animation: spin 1s linear infinite;
      content: '';
      @extend .bsbb;
      position: fixed;
      top: 50%;
      left: 50%;
      margin-top: -40px;
      margin-left: -40px;
      border: 2px solid $light-blue-200;
      border-top: 2px solid $light-blue-800;
      border-radius: 50%;
      width: 80px;
      height: 80px;
    }
  }
  &:after {
    content: '';
    z-index: $zi-loader;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
  }
  &.ajax {
    &:after {
      background-color: rgba($white, 0.7);
    }
  }
  &.upload {
    .circle {
      color:$white;
      font-size: 3em;
      &:after {
        border: 2px solid $light-blue-200;
        border-top: 2px solid $white;
      }
    }
    &:after {
      background-color: rgba($blue, 0.8);
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  width: 100%;
  @extend .bsbb;
  padding: 50px 0;
  @extend .display-flex;
  @extend .flex-center;
  .circle {
    animation: spin 1s linear infinite;
    content: '';
    border: 2px solid $blue-100;
    border-top: 2px solid $blue-900;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
}

.error {
  background: $red;
  padding:5px;
  color: $white;
  display: inline-block;
  margin:20px 0;
}