.App {
  font-family: Arial, Verdana, sans-serif;
}

.full-container {
  height: 100%;
}

.user-info-container {
  margin-bottom: 25px;
  .user_name {
    font-weight: 500;
    font-size: 23px;
  }
}

.table-header {
  background-color: #3f51b5;
  justify-content: space-evenly;
  display: flex;
  // padding: 5px 0px 5px 0px;
  padding: 4px;
  color: white;
}

.column-header {
  font-size: 15px;
  font-weight: 540;
  text-transform: uppercase;
  margin: 5px 0px 5px 0px;
  white-space: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding-left: 10px;
  // padding-right: 10px;
}

.element {
  width: 200px;
  text-align: center;
  // border: 1px solid red
}

.span-resources {
  text-align: center;
  //padding-top: 10px;
  // padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 120px);
  min-width: 350px;
  font-size: 12px;
  .button.delete-resource {
    background-image: url('../assets/remove.svg');
    background-position: center;
    // background-size: cover;
    background-color: #3f51b5;
    cursor: pointer;
  }
}

.span-header {
  text-align: left;
  width: 80%;
}

.span-project {
  text-align: left;
  width: 80%;
  position: relative;
}

.name-block {
  text-align: left;
  width: 80%;
  position: relative;
  margin: 5px;
}

.span-task {
  text-align: left;
  width: 80%;
  padding-left: 20px;
  position: relative;

  &::before {
    content: '';
    background-color: black;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 5px;
  }
}

.span-subtask {
  text-align: left;
  width: 80%;
  padding-left: 90px;
  position: relative;
  line-height: 23px;

  &::before {
    content: '';
    background-color: black;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    position: absolute;
    left: 114px;
    left: 70px;
    top: 6px;
  }
}

.toggle-button {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;

  background-image: url('../assets/arrow.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.input {
  border-radius: 5px;
  width: 180px;
  height: 30px;
  padding-left: 5px;
  margin-right: 20px;
}

.external {
  position: relative;
  // overflow: auto;
  width: 100%;
  height: calc(100% - 210px);
  overflow-x: auto;
  overflow-y: hidden;
}

/**
Classes for buttons, the subclass add image.
.button is an empty button
*/

.spacer-button {
  width: 30px;
  height: 30px;
}

.fls-container {
  margin-top: 70px;
  margin-bottom: 30px;
  .filter-title {
    text-align: left;
    width: 70px;
    margin-top: 30px;
  }

  .filter {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    margin-top: 5px;
    margin-bottom: 20px;
  }
}

/* TAG FOR CONTAINER */

.container {
  min-width: 1500px;
  height: 100%;

  .button {
    content: '';
    width: 30px;
    height: 30px;

    display: flex;
    align-items: center;

    border-radius: 50%;

    // margin-top: 5px;

    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    cursor: pointer;
    background-color: #3f51b5;
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.3);

    &:hover {
      box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.375);
      filter: brightness(120%);
    }

    &.arrow {
      background-image: url('../assets/arrow.svg');
    }

    &.archived {
      color: $white;
      text-align: center;
      @extend .display-flex;
      @extend .flex-center;
    }

    &.button-rec {
      background-image: url('../assets/play.svg');
      &.recording {
        background-image: url('../assets/stop.svg');
      }
    }

    &.button-set_time {
      background-image: url('../assets/clock.svg');
    }

    &.button-add {
      background-image: url('../assets/add.svg');
    }

    &.button-options {
      background-image: url('../assets/options.svg');
      background-color: white;
      box-shadow: none;
    }

    &.button-open {
      transform: rotate(180deg);
    }
  }

  .table-title {
    background-color: #3f51b5;
    justify-content: space-evenly;
    display: flex;
    padding: 5px 0px 5px 0px;
    color: white;
  }

  .user-info {
    display: flex;
    justify-content: flex-start;

    .element {
      width: 200px;
      text-align: left;
      margin: 3px;
    }
  }

  // .column {
  //     display: flex;
  //     flex-direction: column;
  //     padding: 4px;
  // }

  .progress_bar {
    height: 20px;
    width: 50%;
    background-color: #eeeeee;
    margin: 10px;

    .progress_bar_alt {
      height: 20px;
      background-color: #86d51a;
      max-width: 100%;
    }
  }

  //Righe progetto

  .max-element {
    max-height: 40px;
  }

  .description {
    width: calc(50% - 500px);
    span {
      line-height: 23px;
    }
  }

  .name {
    width: calc(50% - 500px);
    @extend .flex-center-start;
    // @extend .flex-direction-column;

    &.detail {
      width: calc(35% - 300px);
    }
    .task-description {
      display: block;
      font-size: 12px;
      width: 80%;
      text-align: left;
      margin-top: 5px;
    }
  }

  .start_date,
  .creation_date {
    width: 5;
  }

  .deadline,
  .edit_date {
    width: 5;
  }

  .status {
    width: 120px;
    select {
      margin: 0px;
    }
  }

  .rec {
    width: 130px;
  }

  .set_time {
    width: 150px;
  }

  //Righe WBS

  .priority {
    width: 120px;
  }

  .resources {
    // width: calc(25% - 66px);
    width: calc(35% - 150px);
    max-width: 600px;
  }

  .estimated_time {
    // width: calc(20% - 66px);
    font-weight: bold;
    width: 120px;
  }

  .spent_time {
    // width: calc(20% - 66px);
    width: 120px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .progress {
    width: 150px;
    display: flex;
    flex-direction: row;
  }

  .sprint {
    // width: 50px;
    width: calc(25% - 300px);

    // &.name {
    //     text-decoration: underline;
    // }
  }

  .percent {
    width: 40px;
  }

  .square {
    width: 20px;
    height: 20px;
    border: solid 1px #adadad;

    &.s-1 {
      background-color: #fafafa;
    }
    &.s-2 {
      background-color: #86d51a;
    }
    &.s-3 {
      background-color: yellow;
    }
    &.s-4 {
      background-color: orange;
    }
    &.s-5 {
      background-color: red;
    }
  }
}

/* TAG FOR ACCORDION (it's also inside container) */

.rows {
  a {
    text-decoration: none;
    color: #000;
  }
  height: 100%;
  max-height: calc(100% - 38px);
  overflow-y: auto;

  .project {
    position: relative;
    font-size: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0px 10px 0px;
    border-top: 1px solid #d4d2d2;
    padding-top: 20px;
    padding-bottom: 20px;

    cursor: pointer;
    &.open {
      padding-bottom: 20px;
    }
    &:hover {
      background: #efefef;
    }

    .element {
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: normal;
      font-size: 17px;
    }
  }

  .task {
    position: relative;
    font-size: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    background: #fafafa;
    &.last {
      padding-bottom: 20px;
    }
    .open {
      transform: rotate(180deg);
    }

    .element {
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: normal;
      font-size: 17px;
      // border: 1px solid red
    }
  }

  .subtask {
    position: relative;
    font-size: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: #f5f5f5;

    .element {
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: normal;
      // padding-right: 10px;
      // padding-left: 10px;
      // border: 1px solid red
    }
  }

  .open {
    .task {
      max-height: 1000px;
      opacity: 1;

      .subtask {
        max-height: 1000px;
        opacity: 1;
      }
    }

    .button.arrow {
      transform: rotate(0deg);
    }
    &.open {
      .button.arrow {
        transform: rotate(180deg);
        box-shadow: 0 -2px 5px 1px rgba(0, 0, 0, 0.3);
        &:hover {
          box-shadow: 0px -4px 11px 0px rgba(0, 0, 0, 0.375);
        }
      }
    }
  }
}

/* TAG ACCORDION PROJECT DETAIL*/

.detail-rows {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100% - 38px);
  overflow-y: auto;

  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.subtask {
      background: #fafafa;
      .button-options {
        background-color: #fafafa;
      }
    }

    &.resources {
      flex-direction: row;
    }

    .task {
      padding-top: 20px;
      padding-bottom: 20px;
      position: relative;
      font-size: 20px;

      display: flex;
      justify-content: space-evenly;
      align-items: center;

      // border-bottom-style: solid;
      // border-bottom-color: rgb(173, 173, 173);
      // border-bottom-color: white;
      // border-bottom-width: 1px;

      .element {
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: normal;
        font-size: 17px;
        a {
          font-size: 17px;
        }
      }

      &.open {
        // border-bottom-color: white;
        .button.arrow {
          transform: rotate(180deg);
          box-shadow: 0 -2px 5px 1px rgba(0, 0, 0, 0.3);
          &:hover {
            box-shadow: 0px -4px 11px 0px rgba(0, 0, 0, 0.375);
          }
        }
      }
    }
  }

  .res {
    display: flex;
    flex-direction: column;

    border-bottom-style: solid;
    border-bottom-color: rgb(173, 173, 173);
    border-bottom-width: 1px;

    &.remove-border {
      border-bottom-color: white;
    }
  }

  .res-row {
    display: flex;
    justify-content: space-evenly;
    .spent_time {
      padding-top: 10px;
      font-size: 15px;
    }
    &.last {
      .span-resources {
        position: relative;
        padding-bottom: 25px;
        // border-bottom: 2px solid #adadad;
        &::after {
          content: '';
          height: 2px;
          max-width: 80%;
          background: #d4d2d2;
          display: block;
          position: absolute;
          right: 0px;
          left: 0px;
          bottom: 0px;
          margin: auto;
        }
      }
      margin-bottom: 20px;
    }
  }

  .subtask {
    position: relative;
    font-size: 20px;
    display: flex;
    justify-content: space-evenly;
    // overflow-y: hidden;
    padding-top: 10px;
    padding-bottom: 10px;

    .element {
      font-size: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: normal;
      // border: 1px solid red
      a {
        font-size: 17px;
      }
    }
  }
}

.add-btn-top-container {
  // position: absolute;
  // top: 20px;
  // right: 200px;
  @extend .display-flex;
  @extend .flex-justify-between;
  @extend .flex-direction-row;
  height: 40px;
  .topinfobar-wrapper {
    // width:100%;
    @include calc('width', '100% - 290px');
    height: 40px;
    margin-bottom: 0;
    .title-wrapper {
      margin-bottom: 0;
    }
  }
}

.fls-top-container {
  .fls-container {
    margin-top: 20px;
    .filter-title {
      margin-top: 0;
    }
  }
}

.archived-notes-line {
  @extend .display-flex;
  @extend .flex-direction-row;
  .archived,
  .restored {
    font-size: 10px;
    text-transform: uppercase;
    padding: 2px;
    color: $white;
    @extend .display-flex;
    @extend .flex-center;
    min-width: 80px;
    width: 80px;
    @include border-radius(2px);
  }
  .archived {
    background: $red;
  }
  .restored {
    background: $green;
  }
  .owner {
    @extend .display-flex;
    @extend .flex-center-start;
    width: 100%;
  }
  .message {
    width: 100%;
    @extend .display-flex;
    @extend .flex-center-start;
  }
  &:not(:first-of-type) {
    margin-top: 10px;
  }
  span {
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
}

.providers-line-c,
.project-line-c {
  position: relative;
  .lnk-project-line {
    @extend .display-flex;
    @extend .flex-center-start;
    @extend .bsbb;
    @include border-radius(2px);
    overflow: hidden;
    margin: 0 0 1px 0;
    text-decoration: none;
    color: $color-text-base;
    position: relative;
    span {
      background: $grey-100;
      @extend .bsbb;
      padding: 10px;
      overflow: hidden;
      white-space: nowrap;
      height: 63px;
      @extend .display-flex;
      @extend .flex-center-start;
      &:not(:first-of-type) {
        border-left: 1px solid $white;
      }
      &.title {
        border-left: 5px solid $indigo;
        min-width: 250px;
        @extend .text-wrap;
      }
      &.customer {
        min-width: 150px;
      }
      &.estimate {
        @extend .flex-center;
        min-width: 90px;
      }
      &.description {
        width: 100%;
        @extend .text-wrap;
      }
      &.date {
        min-width: 140px;
        @extend .flex-center;
        i {
          color: $indigo;
        }
        &.l {
          i {
            margin-right: 10px;
            color: $indigo;
          }
        }
        &.r {
          i {
            margin-left: 10px;
          }
        }
      }
    }
    &:hover {
      span {
        background: $grey-200;
      }
    }
  }
  .tools {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 200px;
    border-left: 0;
    @extend .bsbb;
    padding: 0 10px;
    @extend .display-flex;
    @extend .flex-center-end;
    @extend .bkgFadeTool;
    @include opacity(0);
    .lnk-estimate-clone {
      color: $white;
      background: $blue;
      @include border-radius(50%);
      width: 25px;
      height: 25px;
      font-size: 12px;
      @extend .display-flex;
      @extend .flex-center;
      text-decoration: none;
      &:not(:first-of-type) {
        margin-left: 10px;
      }
      &[disabled] {
        background: $blue-100;
      }
    }
  }
  &:hover {
    .tools {
      @include opacity(1);
    }
  }
}

.task-line {
  @extend .display-flex;
  @extend .flex-center-start;
  @extend .bsbb;
  @include border-radius(2px);
  overflow: hidden;
  margin: 0 0 1px 0;
  text-decoration: none;
  color: $color-text-base;
  padding-left: 251px;
  width: 100%;
  &.sub {
    padding-left: 501px;
    .name {
      border-left: 5px solid $indigo-100;
    }
  }
  span {
    @extend .bsbb;
    padding: 10px;
    overflow: hidden;
    white-space: nowrap;
    height: 43px;
    @extend .display-flex;
    @extend .flex-center-start;
    background: $grey-50;
    &:not(:first-of-type) {
      border-left: 1px solid $white;
    }
    &.name {
      min-width: 250px;
      @include border-radiusd(2px, 0, 0, 2px);
      border-left: 5px solid $indigo-300;
    }
    &.priority {
      min-width: 40px;
      text-align: center;
      &.pr1 {
        color: $light-green;
      }
      &.pr2 {
        color: $green;
      }
      &.pr3 {
        color: $yellow;
      }
      &.pr4 {
        color: $orange;
      }
      &.pr5 {
        color: $red;
      }
    }
    &.description {
      width: 100%;
    }
    &.status {
      min-width: 140px;
    }
    &.btns {
      min-width: 70px;
      padding: 0;
      @extend .display-flex;
      @extend .flex-center;
      button {
        @extend .display-flex;
        @extend .flex-center;
        background: $indigo;
        color: $white;
        min-width: 30px;
        min-height: 30px;
        @include border-radius(50%);
      }
    }
  }
  &:hover {
    span {
      background: $grey-100;
    }
  }
}

.project-editor-tool-container {
  position: absolute;
  top: 20px;
  right: 20px;
  @extend .display-flex;
  @extend .flex-direction-row;
}

.pp-progress-bar {
  @extend .display-flex;
  @extend .flex-direction-column;
  > div {
    width: 100%;
    @extend .display-flex;
    font-weight: 700;
  }
  .estimated {
    @extend .flex-center-end;
    height: 30px;
  }
  .bar {
    background: $grey-300;
    height: 30px;
    @include border-radius(2px);
    overflow: hidden;
    position: relative;

    .barc {
      position: absolute;
      top: 0;
      left: 0;
      background: $blue;
      height: 30px;
      @include border-radius(2px);
    }
    .perc {
      position: absolute;
      top: 0;
      left: 0;
      height: 30px;
      width: 100%;
      @extend .display-flex;
      @extend .flex-center;
      color: $white;
    }
  }
  .spent {
    height: 30px;
    max-width: 100%;
    min-width: 50px;
    // background: red;
    @extend .flex-center-start;
    @extend .bsbb;
    &.right {
      @extend .flex-center-end;
    }
  }
}

.chips-inline-c {
  span {
    margin: 0 10px 10px 0;
  }
}

.task-container {
  &:not(:first-of-type) {
    margin-top: 20px;
  }
}

.prj-task {
  @extend .display-flex;
  @extend .flex-direction-row;
  &:not(:first-of-type) {
    margin-top: 10px;
  }
  &.sub {
    margin-left: 150px;
  }
  background: $blue-grey-50;
  @include border-radius(5px);
  > span {
    @extend .bsbb;
    padding: 10px 10px;
    @extend .display-flex;
    @extend .flex-center;
    min-height: 50px;
    &:not(:last-of-type) {
      border-right: 1px solid $white;
    }
    &.td {
      min-width: 250px;
      @extend .flex-direction-column;
      > span {
        padding: 0;
        width: 100%;
        @extend .display-flex;
        @extend .flex-center-start;
      }
      .title {
        text-transform: uppercase;
        font-weight: 700;
      }
      .description {
        margin-top: 5px;
        &:empty {
          display: none;
        }
      }
    }
    &.resources {
      width: 100%;
      // height: 150px;
      @extend .flex-center-start;
      @extend .flex-wrap;
      > span {
        margin: 5px 5px 0 0;
      }
    }
    &.priority {
      min-width: 40px;
      &.pr1 {
        color: $light-green;
      }
      &.pr2 {
        color: $green;
      }
      &.pr3 {
        color: $yellow;
      }
      &.pr4 {
        color: $orange;
      }
      &.pr5 {
        color: $red;
      }
    }
    &.time {
      min-width: 120px;
      @extend .flex-center-end;
      i {
        margin-left: 10px;
        color: $indigo;
      }
    }
    &.sprint {
      min-width: 150px;
      @extend .flex-center-start;
      > a {
        color: $blue;
        text-decoration: none;
        font-weight: 700;
      }
    }
    &.progress {
      min-width: 100px;
      p {
        width: 100%;
        background: $white;
        border: 1px solid $blue-100;
        height: 5px;
        @include border-radius(5px);
        content: '';
        position: relative;
        overflow: hidden;
        b {
          position: absolute;
          content: '';
          background: $blue;
          height: 100%;
        }
      }
    }
    &.dropdown {
      min-width: 40px;
      padding: 10px 0;
      position: relative;
      .dropdown-wrapper {
        cursor: pointer;
        position: absolute;
        top: 5px;
        left: 0;
        width: 30px;
        height: 30px;
        // background: red;
        .button {
          width: 100%;
          height: 100%;
        }
      }
    }
    &.tools {
      min-width: 140px;
    }
  }
}

.prj-btn-edit {
  @include border-radius(50%);
  @extend .display-flex;
  @extend .flex-center;
  background: $blue-300;
  color: $white;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  font-size: 10px;
  &:not(:last-of-type) {
    margin-right: 10px;
  }
  &:hover {
    background: $blue;
  }
}

.btn-add-resource {
  @include border-radius(50%);
  @extend .display-flex;
  @extend .flex-center;
  margin-right: 10px;
  background: $blue-300;
  color: $white;
  width: 30px;
  height: 30px;
  &:hover {
    background: $blue;
  }
}

.ul-project-providers {
  li {
    @extend .display-flex;
    @extend .flex-direction-row;
    @extend .flex-justify-between;
    @extend .flex-center;
    // padding: 5px 0 5px 5px;
    @include border-radius(5px);
    padding-left: 10px;
    &:hover {
      background: $grey-50;
    }
    &:not(:first-of-type) {
      margin-top: 5px;
    }
    span {
      // padding: 10px 10px 15px;
      button {
        width: 30px;
        height: 30px;
        background: $red;
        color: $white;
        padding-bottom: 0;
      }
      // input {
      //   margin: 0;
      //   width: 200px;
      //   // margin-left: 10px;
      // }
      &.name {
        background: transparent;
        width: 100%;
        padding-top: 20px;
        word-break: break-word;
      }
      &.description {
        // min-width: 300px;
        width: 100%;
      }
      &.amount {
        min-width: 150px;
        @extend .flex-center-end;
      }
      &.number {
        min-width: 150px;
      }
      &.date {
        min-width: 200px;
      }
      &.button {
        // min-width: 50px;
        padding-top: 20px;
      }
      &:not(:last-of-type) {
        padding-right: 10px;
      }
    }
  }
}
