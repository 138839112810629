@import 'colors';
@import 'mixin';

.auth-layout {
  &.blur {
    nav,
    main {
      @include blur(5px);
    }
  }
}

.login-bkg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  img {
    @extend .image-fit;
  }
}

.login-container {
  @extend .display-flex;
  @extend .flex-center;
  @extend .flex-direction-column;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  .login-wrapper {
    padding: 30px 70px;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 4px 60px 0px #00000099;
    width: 450px;

    .site-title-wrapper {
      display: flex;
      justify-content: center;

      .site-title {
        font-size: 4em;
        margin-bottom: 0;
        color: #1a1a1a;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      }

      .version {
        color: #1a1a1a;
        margin-top: auto;
        padding-bottom: 5px;
        margin-bottom: 0;
      }
    }

    form {
      margin-top: 20px;
      @extend .display-flex;
      @extend .flex-direction-column;
      width: 300px;

      .btn-login {
        margin: 20px 0 10px 0;
        width: auto;
        padding: 10px 0;
        text-transform: uppercase;
      }
    }

    .social-login {
      @extend .display-flex;
      @extend .flex-center;
      @extend .flex-direction-column;
      font-weight: 700;
      font-size: 12px;
      button {
        margin-top: 10px;
      }
    }

    .response-error {
      margin-top: 20px;
      display: block;
      text-align: center;
    }
  }
}

.site-title {
  font-size: 4em;
  margin-bottom: 0;
  color: $white;
  text-shadow: 2px 2px 10px rgba($black, 0.5);
}

// .version {
//     color: $white;
//     margin-bottom: 20px;
// }

.response-error {
  color: $red;
  // background: $red;
  font-weight: bold;
  margin-top: 20px;
  // padding:2px;
  &:empty {
    display: none;
  }
}

nav {
  position: fixed;
  left: 0;
  right: 0;
  width: 200px;
  height: 100%;
  @extend .display-flex;
  @extend .flex-direction-column;
  background: $indigo;
  z-index: $zi-nav;
  @extend .bsbb;
  padding: 10px;
  justify-content: space-between;

  .profile {
    @extend .display-flex;
    @extend .flex-center-start;
    padding: 0;
    justify-content: flex-start;

    .avatar {
      min-width: 50px;
      max-width: 50px;
      height: 50px;
      margin-right: 10px;
      @include border-radius(5px);
      overflow: hidden;

      img {
        @extend .image-fit;
      }
    }

    .name {
      height: 50px;
      overflow: hidden;
      @extend .display-flex;
      @extend .flex-center;
    }
  }

  &.collapsed {
    width: 70px;
    overflow: hidden;
  }
}

.menu {
  @extend .display-flex;
  // @extend .flex-center;
  @extend .flex-direction-column;
  width: 100%;
}

.menu {
  @extend .display-flex;
  // @extend .flex-center;
  @extend .flex-direction-column;
  width: 100%;
}

.submenu {
  position: fixed;
  top: 70px;
  width: 250px;
  background: $white;
  @include border-radius(5px);
  border: 1px solid $grey;
  @include box-shadow(0, 0, 5px, 0, rgba($black, 0.5));
  @extend .bsbb;
  padding: 10px 0;
}

.menu-scroll {
  width: 100%;
  overflow: auto;
  overflow-x: clip;
}

.right-pan {
  width: 100%;
  height: 100vh;
  @extend .bsbb;
  padding: 20px 20px 20px 220px;
  position: relative;
  overflow: auto;
  &.collapsed {
    padding: 20px 20px 20px 90px;
  }
}

/* toastify */

.Toastify__toast--success {
  background: $green !important;
}

.Toastify__toast--info {
  background: $blue !important;
}

.Toastify__close-button--error {
  background: $red !important;
}

.Toastify__toast--warning {
  background: $amber !important;
}

.Toastify__toast-body {
  text-transform: uppercase;
  font-weight: 700;
}

.__react_component_tooltip {
  p {
    margin: 5px 0;
    span {
      color: $blue;
      font-weight: 700;
    }
  }
}

/* MODAL */
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $zi-modal-container;
  overflow: auto;
  @extend .bsbb;
  @media screen and (min-width: 320px) {
    padding: 5px;
  }
  @media screen and (min-width: 480px) {
    padding: 20px;
  }
  @media screen and (min-width: 768px) {
    padding: 50px;
  }
  align-items: flex-start;
  justify-content: center;
  @extend .display-flex;
  .modal {
    @extend .display-flex;
    flex-direction: column; //height: 100vh;
    background-color: $white;
    @include border-radius(5px);
    width: 600px;
    @extend .bsbb;
    padding: 20px; //min-height: min-content;
    position: relative;
    h1 {
      margin-right: 30px;
    }
    h3 {
      padding-bottom: 20px;
    }
    .modal-message {
      display: block;
      margin: 0 0 20px 0;
      &:empty {
        display: none;
      }
    }
    > label {
      text-transform: uppercase;
      color: $grey;
      font-size: 11px;
      font-weight: 700;
      @extend .ulnone;
      @extend .display-flex;
      @extend .flex-direction-column;
      color: $blue-300;
      b {
        color: $color-text-base;
        font-weight: 400;
        font-size: 15px;
        margin-top: 10px;
      }
      textarea {
        margin: 5px 0 0 0;
        padding: 10px;
      }
      textarea {
        height: 100px;
      }
      // textarea, input {
      //   border:1px solid $white;
      // }
      &:not(:first-of-type) {
        padding-top: 20px;
      }
    }
    .submit-btn {
      @extend .ulnone;
      @extend .display-flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-top: 20px !important;
      align-content: center;
      align-items: center;
      li {
        &.li-modal-delete {
          width: 100%;
        }
        &:not(:last-of-type) {
          padding-right: 10px;
        }
      }
    }
  }
  &:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($grey, 0.5);
    z-index: -1;
  }
}

.input-error {
  border: 1px solid $red;
}

.nav-year {
  @extend .display-flex;
  @extend .flex-direction-row;
  @extend .flex-center;
  @extend .flex-justify-between;
  color: $white;
  @extend .flex-wrap;
  button {
    margin: 2px 0;
    @extend .display-flex;
    @extend .flex-center;
    min-height: 50px;
    min-width: 50px;
    background: transparent;
    @include border-radius(5px);
    color: rgba($white, 0.5);
    &:hover {
      color: $white;
      background: rgba($white, 0.2);
    }
    &.im-white {
      color: $white;
    }
    &.collapsed {
      display: none;
    }
  }
}
