@import 'colors';
@import 'mixin';

.tbi-c {
  @extend .display-flex;
  @extend .flex-direction-column;
  // @extend .fle<x-center-start;
  // @extend .bsbb;
  // @include border-radius(2px);
  // overflow: hidden;
  // margin: 0 0 1px 0;
  // text-decoration: none;
  // color:$color-text-base;
  // margin-bottom: 5px;
  // cursor: pointer;
  // &:hover {
  //   .e-info, .e-stories > .tbi-line-c {
  //     span {
  //       background: $grey-200;
  //     }
  //   }
  // }
  .e-info {
    @extend .display-flex;
    @extend .flex-direction-row;
    width: 100%;
    @include border-radius(2px);
    overflow: hidden;
    margin: 0 0 1px 0;
    &:hover {
      span {
        background: $grey-200;
      }
    }
    a {
      @extend .display-flex;
      @extend .flex-direction-row;
      width: 100%;
      text-decoration: none;
      color: $color-text-base;
      span {
        background: $grey-100;
        @extend .bsbb;
        padding: 10px;
        overflow: hidden;
        white-space: nowrap;
        height: 33px;
        @extend .display-flex;
        @extend .flex-center-start;
        &:not(:first-of-type) {
          border-left: 1px solid $white;
        }
        &.number {
          min-width: 80px;
          text-align: center;
        }
        &.title {
          width: 100%;
        }
        &.customer {
          min-width: 200px;
        }
        &.total {
          min-width: 150px;
        }
        &.progress {
          min-width: 100px;
          p {
            width: 100%;
            background: $white;
            border: 1px solid $blue-100;
            height: 5px;
            @include border-radius(5px);
            content: '';
            position: relative;
            overflow: hidden;
            b {
              position: absolute;
              content: '';
              background: $blue;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .e-stories {
    margin-bottom: 5px;
    .tbi-line-c {
      margin-left: 80px;
      &:hover {
        span {
          background: $grey-200;
        }
      }
      span {
        background: $grey-100;
        @extend .bsbb;
        padding: 10px;
        overflow: hidden;
        white-space: nowrap;
        height: 33px;
        @extend .display-flex;
        @extend .flex-center-start;
        &:not(:first-of-type) {
          border-left: 1px solid $white;
        }
        &.date {
          min-width: 155px;
        }
        &.title {
          width: 100%;
        }
        &.button {
          min-width: 100px;
        }
        &.amount {
          min-width: 150px;
          @extend .flex-center-end;
        }
      }
    }
  }
}

.tbi-line-c {
  @extend .display-flex;
  @extend .flex-center-start;
  @extend .bsbb;
  @include border-radius(2px);
  overflow: hidden;
  margin: 0 0 1px 0;
  text-decoration: none;
  color: $color-text-base;
  position: relative;
}

.invoices-nav {
  @extend .display-flex;
  @extend .flex-direction-row;
  position: absolute;
  top: 20px;
  right: 20px;
  li {
    &:not(:first-of-type) {
      margin-left: 20px;
    }
    text-transform: uppercase;
    @extend .display-flex;
    @extend .flex-center;
    button {
      width: 40px;
    }
    &.current {
      width: 100px;
      // background: $blue-300;
      @include border-radius(5px);
      color: $blue;
      font-weight: 700;
    }
  }
}

.invoices-c {
  margin-top: 20px;
}

.invoice-line-c {
  @extend .display-flex;
  @extend .flex-direction-row;
  width: 100%;
  @include border-radius(2px);
  overflow: hidden;
  margin: 0 0 1px 0;
  &.form-disabled {
    .button {
      pointer-events: auto;
      button {
        &.green {
          background: $green;
          color: $white;
          pointer-events: auto;
        }
      }
    }
  }
  &.header {
    span {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 700;
      color: $blue;
      @extend .white-space-pre-wrap;
      word-wrap: break-word;
    }
  }
  &:not(.header) {
    &:hover {
      span {
        border-top: 1px solid $grey-200;
        border-bottom: 1px solid $grey-200;
        .lnk-del-eh {
          @include opacity(1);
        }
      }
    }
  }
  span {
    @extend .bsbb;
    padding: 5px;
    overflow: hidden;
    // white-space: nowrap;
    // height: 43px;
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
    @extend .display-flex;
    @extend .flex-center-start;
    &:not(:first-of-type) {
      border-left: 1px solid $white;
    }
    &.del {
      background: transparent;
      min-width: 40px;
    }
    &.eid {
      min-width: 100px;
    }
    &.customer {
      min-width: 100px;
    }
    &.title {
      width: 100%;
    }
    &.amount {
      min-width: 150px;
      @extend .flex-center-end;
    }
    &.oda {
      min-width: 150px;
    }
    &.bem {
      min-width: 100px;
    }
    &.number {
      min-width: 150px;
    }
    &.year {
      min-width: 100px;
    }
    &.expire {
      min-width: 180px;
    }
    &.button {
      min-width: 40px;
    }
  }
}
