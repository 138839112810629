@import 'colors';
@import 'mixin';

.estimate-status {
  text-align: right;
}

.create-project {
  cursor: pointer;
  margin-right: 90px;
}

.estimate-bar {
  position: fixed;
  top: 0;
  left: 0;
  height: 5px;
  width: 100%;
  z-index: 1;
  &.draft {
    background: $color-draft;
  }
  &.waiting {
    background: $color-waiting;
  }
  &.confirmed {
    background: $color-confirmed;
  }
  &.refused {
    background: $color-refused;
  }
  &.invoiced {
    background: $color-invoiced;
  }
}

.estimate-configurator-bone {
  @extend .display-flex;
  @extend .flex-direction-row;
  li {
    @extend .bsbb;
    &.registry {
      min-width: 200px;
      max-width: 200px;
      // max-width:150px;
      margin-right: 10px;
      position: relative;
      .registry-default {
        width: 16px;
        height: 16px;
        font-size: 10px;
        position: absolute;
        top: 0;
        right: 0;
        background: transparent;
        color: $red;
        @include border-radius(50%);
        @extend .display-flex;
        @extend .flex-center;
        &:hover {
          background: $red;
          color: $white;
        }
      }
      .roles {
        > li {
          label {
            span {
              float: right;
              font-size: 10px;
              margin: 0;
            }
          }
        }
      }
    }
    &.epics {
      margin-left: 10px;
      width: 100%;
    }
  }
}

.form-add-epic {
  width: 100%;
  @extend .display-flex;
  @extend .flex-direction-row;
  > li {
    @extend .flex-center-start;
    @extend .display-flex;
    &.txt {
      width: 100%;
    }
    &.add {
      min-width: 30px;
      margin-left: 10px;
    }
  }
  &.multi {
    li {
      &.txt {
        &:not(:first-of-type) {
          margin-left: 10px;
        }
      }
    }
  }
  &.list {
    &:not(:first-of-type) {
      margin-top: 5px;
    }
  }
}

.form-add-program {
  width: 100%;
  @extend .display-flex;
  @extend .flex-direction-row;
  > li {
    @extend .flex-center-start;
    @extend .display-flex;
    &:not(:first-of-type) {
      margin-left: 10px;
    }
    &.number {
      min-width: 100px;
      max-width: 100px;
    }
    &.date {
      min-width: 150px;
    }
    &.txt {
      width: 100%;
    }
    &.add {
      min-width: 30px;
      margin-left: 10px;
    }
  }
}

._base-epics {
  @extend .display-flex;
  @extend .flex-direction-row;
  li {
    @extend .display-flex;
    @extend .flex-center;
    &.epic-menu {
      min-width: 20px;
    }
    &.txt {
      width: 100%;
    }
    &.txt-left {
      @extend .flex-center-start;
    }
    &.num {
      @extend .bsbb;
      min-width: 100px;
      max-width: 100px;
      margin-left: 10px;
      @extend .display-flex;
      @extend .flex-center;
      font-size: 15px;
    }
    &.date {
      min-width: 200px;
    }
    &:not(:first-of-type) {
      margin-left: 10px;
    }
  }
  &:hover {
    li {
      .lnk-del-eh {
        @include opacity(1);
      }
    }
  }
}

.epics-list {
  @extend .display-flex;
  @extend .flex-direction-column;
  > li {
    margin-top: 30px;
    .head {
      @extend ._base-epics;
    }
    .hero {
      @extend ._base-epics;
      li {
        input,
        select {
          margin: 5px 0;
          border: 1px solid $white;
          height: 30px;
          &.touched {
            border: 1px solid $blue;
          }
        }
      }
    }
    .add-hero {
      @extend ._base-epics;
      margin-left: 30px;
      li {
        width: 100%;
        input,
        select {
          margin: 5px 0;
          background: $grey-100;
          font-size: 12px;
        }
        select {
          color: $grey-700;
          // font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
    .bottom {
      @extend ._base-epics;
      li {
        height: 30px;
        color: $blue;
        overflow: hidden;
        input {
          border: 1px solid $white;
          &.touched {
            border: 1px solid $blue;
          }
        }
      }
    }
  }
}

.epic-total {
  margin: 20px 0 50px 0;
  @extend .display-flex;
  @extend .flex-direction-row;
  border-top: 1px solid $grey;
  border-bottom: 1px solid $grey;
  @extend ._base-epics;
  li {
    padding: 10px 0;
    text-transform: uppercase;
    font-weight: bold;
    overflow: hidden;
    &.txt {
      @extend .flex-center-end;
      margin-right: 10px;
    }
    &.date {
      @extend .flex-center-start;
      min-width: 180px;
    }
  }
}

.stories-list {
  @extend .display-flex;
  @extend .flex-direction-column;
  > li {
    .head {
      @extend ._base-epics;
      margin-top: 10px;
      .epic-menu {
        min-width: 50px;
      }
    }
    .story {
      @extend ._base-epics;
      li {
        input,
        select {
          margin: 5px 0;
          border: 1px solid $white;
          height: 30px;
        }
      }
    }
  }
}

.editable {
  background: $white;
  font-weight: bold;
  color: $blue;
  text-decoration: underline;
  &:hover,
  &:focus {
    background: $grey-200;
    font-weight: normal;
    color: $color-text-base;
    text-decoration: none;
  }
}

.status-change {
  @extend .display-flex;
  @extend .flex-direction-row;
  @extend .flex-justify-between;
  margin: 10px 0 30px 0;
  font-size: 30px;
  span {
    text-transform: uppercase;
    width: 230px;
    @extend .status-colors;
    font-weight: 700;
    &:first-of-type {
      text-align: right;
    }
    &:last-of-type {
      text-align: left;
    }
  }
  i {
    font-size: 50px;
  }
}

$bl: 5px;

.lnk-estimate-line {
  @extend .display-flex;
  @extend .flex-center-start;
  @extend .bsbb;
  @include border-radius(2px);
  overflow: hidden;
  margin: 0 0 1px 0;
  text-decoration: none;
  color: $color-text-base;
  position: relative;
  &.draft {
    border-left: $bl solid $color-draft;
  }
  &.waiting {
    border-left: $bl solid $color-waiting;
  }
  &.confirmed {
    border-left: $bl solid $color-confirmed;
  }
  &.refused {
    border-left: $bl solid $color-refused;
  }
  &.invoiced {
    border-left: $bl solid $color-invoiced;
  }
  span {
    background: $grey-100;
    @extend .bsbb;
    padding: 10px;
    overflow: hidden;
    white-space: nowrap;
    height: 33px;
    @extend .display-flex;
    @extend .flex-center-start;
    &:not(:first-of-type) {
      border-left: 1px solid $white;
    }
    &.number {
      min-width: 80px;
      text-align: center;
    }
    &.title {
      width: 100%;
    }
    &.account,
    &.customer {
      min-width: 200px;
      img {
        height: 20px;
        margin-right: 5px;
      }
    }
    &.amount {
      min-width: 150px;
      @extend .flex-center-end;
    }
    &.date {
      min-width: 155px;
      @extend .flex-center-end;
    }
  }
  &:hover {
    span {
      background: $grey-200;
    }
  }
}

.estimate-line-c {
  position: relative;
  .clone {
    position: absolute;
    right: 0;
    top: 0;
    height: 33px;
    width: 150px;
    border-left: 0;
    @extend .bsbb;
    padding: 0 10px;
    @extend .display-flex;
    @extend .flex-center-end;
    @extend .bkgFadeTool;
    @include opacity(0);
    .lnk-estimate-clone {
      color: $white;
      background: $blue;
      @include border-radius(50%);
      width: 25px;
      height: 25px;
      font-size: 12px;
      @extend .display-flex;
      @extend .flex-center;
      text-decoration: none;
      // &:hover {
      //   color:$blue;
      //   background: $white;
      // }
    }
  }
  &:hover {
    .clone {
      @include opacity(1);
    }
  }
}

.span-add-customer {
  @extend .display-flex;
  @extend .flex-direction-row;
  button {
    min-width: 30px;
    min-height: 30px;
    max-height: 30px;
    margin-left: 10px;
    background: $green;
    color: $white;
    margin-top: 5px;
  }
}

.estimate-filter-line {
  @extend .display-flex;

  .select-year {
    width: 100px;
    box-sizing: border-box;
  }

  input {
    margin-right: 10px;
    margin-left: 10px;
    box-sizing: border-box;
    width: 30%;
  }
}
