.topinfobar-wrapper {
  margin-bottom: 40px;
  .title-wrapper {
    display: flex;
    flex-flow: row;
    margin-bottom: 40px;

    .back {
      color: #2196f3;
      margin-right: 15px;
      transform: scale(1.2);
      transition: opacity 100ms ease-in;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    .buttons-wrapper {
      margin-left: auto;

      button {
        margin-left: 10px;
        padding: 10px 20px;
        background: #2196f3;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        border-radius: 2px;
        height: 40px;

        i {
          margin-right: 10px;
        }
      }
    }
  }
  .info-wrapper {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 20px;
    p,
    span {
      font-size: 20px;
    }
    div {
      display: inline-flex;

      span {
        margin-left: 5px;
      }
    }
  }
  .progressbar-wrapper {
    position: relative;
    .outer-bar {
      background: #e0dfdf;
      height: 30px;
      width: 100%;
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      .percentual {
        color: #ffffff;
        z-index: 9;
        font-size: 20px;
        font-weight: bold;
      }

      .inner-bar {
        float: left;
        background: #86d51a;
        height: 100%;
        position: absolute;
        left: 0px;
        max-width: 100%;
      }
    }
  }
}
