@import 'colors';
@import 'mixin';

.dash-charts {
  @extend .display-flex;
  @extend .flex-center-start;
  @extend .flex-wrap;
  li {
    width: 50%;
  }
}

.amount-status {
  text-align: center;
}

.filters-container {
  @extend .display-flex;
  @extend .flex-center;
}

.amount-container {
  display: block;
  text-align: right;
}

.filters-list {
  // border-top: 1px solid $grey-100;
  // border-bottom: 1px solid $grey-100;
  flex: 1;
  padding: 10px 0;
  @extend .display-flex;
  @extend .flex-center-start;
  @extend .flex-direction-row;
  @extend .flex-wrap;
  margin: 10px 0;

  li {
    @extend .display-flex;
    @extend .flex-center-start;
    // color: $color-text-base;
    select {
      min-width: 150px;
    }
    input {
      min-width: 200px;
    }
    &:not(:last-of-type) {
      margin-right: 10px;
    }
    label {
      &.switch {
        margin-left: 10px;
      }
    }
    &.li-chips {
      > span {
        &:not(:first-of-type) {
          margin-left: 10px;
        }
      }
      @extend .flex-wrap;
    }
    // .ul-li-filter-chips {
    //   @extend .display-flex;
    //   min-width: 100%;
    //   @extend .flex-direction-row;
    // }
  }
}

.title-section {
  font-size: 20px;
  margin: 20px 0;
  font-weight: bold;
  text-transform: uppercase;

  &:not(:first-of-type) {
    margin: 40px 0 20px 0;
  }

  @extend .status-colors;
  @extend .display-flex;
  @extend .flex-justify-between;
  // @extend .space-between-x;

  span {
    // font-size: initial;
    // color: initial;
    font-weight: initial;
    // color: initial;
  }
}

.ul-edit-form {
  @extend .display-flex;
  @extend .flex-direction-row;
  @extend .flex-wrap;

  li {
    width: 50%;
    @extend .bsbb;

    &:nth-child(odd) {
      padding-right: 10px;
    }

    &:nth-child(even) {
      padding-left: 10px;
    }
  }
  li.not-selectable {
    pointer-events: none;
  }
}

label {
  text-transform: uppercase;
  font-size: 10px;
  color: $blue;
  font-weight: bold;
  margin-top: 10px;
  display: block;

  input,
  select,
  textarea,
  span {
    margin: 5px 0;
    text-transform: none;
  }
  span {
    display: block;
    color: $color-text-base;
    font-size: 15px;
    font-weight: 400;
  }
}

.bottom-utils-buttons {
  @extend .display-flex;
  @extend .flex-direction-row;
  margin: 20px 0;
  > li {
    width: 100%;
    &.left {
      @extend .display-flex;
      @extend .flex-center-start;
    }
    &.right {
      @extend .display-flex;
      @extend .flex-center-end;
    }
    .ul-utils-btn {
      @extend .display-flex;
      li {
        &:not(:first-of-type) {
          margin-left: 10px;
        }
      }
    }
  }
}

.estimate-container {
  @extend .display-flex;
  @extend .flex-direction-row;
  width: 100%;
  .info {
    width: 100%;
  }
  .version {
    min-width: 20%;
    word-break: break-all;
    max-width: 20%;
    @extend .bsbb;
    padding: 0 0 0 20px;
    .navigator {
      @extend .display-flex;
      @extend .flex-direction-row;
      @extend .flex-center-end;
      li {
        &:not(:first-of-type) {
          margin-left: 10px;
        }
      }
    }
    label {
      &:not(:first-of-type) {
        margin-top: 20px;
      }
      span {
        min-height: 15px;
        &.bold {
          @extend .status-colors;
          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }
  }
}

.form-disabled {
  pointer-events: none;
  input,
  select,
  textarea,
  button {
    &:not(.alwaysEnabled) {
      color: $grey;
      background: $grey-300;
      pointer-events: none;
    }
    &.alwaysEnabled {
      pointer-events: auto;
    }
  }
  .lnk-del-eh {
    display: none;
  }
  [data-tip] {
    pointer-events: auto;
  }
}

.animals-list {
  @extend .display-flex;
  @extend .flex-direction-row;
  @extend .flex-wrap;
  width: 100%;
  margin-bottom: 50px;
  li {
    @extend .bsbb;
    padding: 15px;
    width: 70px;
    height: 70px;
    @extend .display-flex;
    @extend .flex-center;
    cursor: pointer;
    @extend .trstn;
    img {
      width: 100%;
      height: 100%;
    }
    &:hover {
      padding: 0;
      @extend .trstn;
    }
  }
}

.toast-notification {
  span {
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 700;
  }
}
