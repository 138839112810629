.dialog {
  &.display-block {
    display: block;
  }

  &.display-none {
    display: none;
  }

  .modal-container {
    align-items: center;
    .modal {
      min-width: 400px;
      max-width: 800px;
      .title {
        margin-top: 10px;
        text-align: left;
      }
      .subtitle {
        padding-top: 20px;
        padding-bottom: 30px;
      }

      .lnk-close-modal {
        top: 15px;
        right: 12px;
      }

      .validation-error {
        color: #f54c40;
        border-color: #f44336;
      }

      .response-error {
        margin-top: 0;
        margin-bottom: 20px;
        text-align: center;
        text-transform: uppercase;
      }

      input {
        background-color: #ffffff;
        border-bottom: 1px solid gray;
        box-shadow: inset 0px -8px 3px -10px black;
        border-radius: 4px 4px 0px 0px;
        padding: 10px 5px 0px;
        color: #2e2e2e;
        // transition: background-color 100ms ease-in-out;
        // padding: 10px 5px 0px;
      }

      // input:focus {
      //     background-color: #eeeeee;
      // }

      select {
        background-color: #ffffff00;
        border-bottom: 1px solid gray;
        box-shadow: inset 0px -8px 3px -10px black;
        padding: 10px 5px 0px;
        border-radius: 4px 4px 0px 0px;
        transition: background-color 100ms ease-in-out;
        color: #2e2e2e;
      }

      select:focus {
        background-color: #eeeeee;
      }

      select::after {
        right: 5px;
        content: '▼';
        height: 10px;
        width: 10px;
        background-color: #0a0a0a;
      }

      label {
        color: #0a0a0a;
        font-size: 1em;
        font-weight: normal;
        text-transform: none;
      }

      form {
        display: flex;
        flex-flow: wrap;
        // padding-top: 15px;
        // padding-bottom: 15px;

        .col12 {
          width: 100%;
          padding: 15px;
        }

        .col9 {
          width: 75%;
          padding: 15px;
        }

        .col8 {
          width: 66.6%;
          padding: 15px;
        }

        .col6 {
          width: 50%;
          padding: 15px;
        }

        .col4 {
          width: 33.33%;
          padding: 15px;
        }

        .col3 {
          width: 25%;
          padding: 15px;
        }

        input[type='radio'] {
          appearance: radio;
          width: 20px;
          margin: 0;
        }
        input[type='checkbox'] {
          appearance: checkbox;
          width: 20px;
          float: right;
          margin: 0;
          margin-top: -20px;
        }
        .radio-wrapper label {
          display: inline-block;
          margin-right: 5px;
          margin-top: 3px;

          span {
            text-transform: uppercase;
            font-size: 10px;
            color: #2196f3;
            font-weight: normal;
          }
        }
        .radio-wrapper label:first-child {
          display: block;
          font-weight: bold;
        }
      }

      .buttons-wrapper {
        display: flex;
        margin-left: auto;
        button {
          width: fit-content;
          padding: 10px 30px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
